:root {
  --light-background1: #a8dadc;
  --light-background: #ffffff;
  --dark-background1: #1d3557;
  --dark-background: #2B2930;
  --primary-accent: #457b9d;
  --secondary-accent: #ff00e7;
  --primary-text: #2B2930;
  --secondary-text: #dcaaa8;
  --primary-shadow: #ff1493;
  --primary-text-outline: #00C5FF;
  --secondary-text-outline: #e3ff00;
  --tertiary-text-outline: #ff00e7;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--dark-background) var(--light-background);
  scroll-behavior: smooth;
}

html {
  font-family: "Dosis", sans-serif;
}

header {
  z-index: 1;
}

body {
  margin: 0;
  background-color: var(--light-background);
  /* color: var(--primary-text);
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


canvas {
  width: 100%;
  height: 100%;
}

.canvas-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

div,
section,
header,
footer,
p,
h1,
h2 {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--primary-text);
  transition: color 250ms linear;
}

a:hover {
  color: var(--secondary-accent);
  transition: color 250ms linear;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--dark-background);
  border: none;
}

.body {
  height: 100vh;
  font-size: 1rem;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

h1 {
  font-size: 10rem;
  font-weight: 700;
  margin: 2rem;
  padding-bottom: 1rem;
  /* font-family: "Permanent Marker", cursive; */
  /* -webkit-transform: skewY(-5deg);
  -moz-transform: skewY(-5deg);
  transform: skewY(-5deg); */
}

h2 {
  font-size: 4rem;
  font-weight: 900;
  margin: 1rem;
}

p {
  font-size: 2rem;
  margin: 1rem;
  font-weight: 900;
}

.loader {
  font-family: monospace, sans-serif;
  font-size: 1em;
  font-weight: 700;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: blink 500ms ease-in-out;
  animation-iteration-count: infinite;
}

.global-width {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 1rem;
}

.content__name {
  animation: blink 250ms ease-in-out;
  animation-iteration-count: 6;
}

.main {
  display: flex;
  flex-direction: column;
  padding: 0 16vw;
  align-items: center;
}

.header-container {
  height: 100vh;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-align: -webkit-center;
}

.content__primary-header {
  position:relative;
}

.content__overlap1 {
  position: absolute;
  top: -5px;
  left: 5px;
  width: 100%;
  -webkit-text-stroke: 0.1rem var(--primary-text-outline);
  text-stroke: 0.1rem var(--primary-text-outline);
  color: transparent;
}

.content__overlap2 {
  position: absolute;
  top: 10px;
  left: -10px;
  width: 100%;
  -webkit-text-stroke: 0.1rem var(--secondary-text-outline);
  text-stroke: 0.1rem var(--secondary-text-outline);
  color: transparent;
}

.content__overlap3 {
  position: absolute;
  top: 3px;
  left: -5px;
  width: 100%;
  -webkit-text-stroke: 0.1rem var(--tertiary-text-outline);
  text-stroke: 0.1rem var(--tertiary-text-outline);
  color: transparent;
}

.text-container {
  padding-top: 40vh;
  position: relative;
  width: 100%;
}

.text-container--left {
  max-width: 900px;
  margin: 0 auto 0 0;
}

.text-container--right {
  max-width: 900px;
  text-align: right;
  margin: 0 0 0 auto;
}

.text-container--bottom {
  margin-bottom: 60vh;
}

.text-shadow {
  color: var(--primary-text);

  text-shadow: 0px 0px 0px var(--primary-shadow),
    0px 1px 0 var(--primary-shadow), 0px 2px 0 var(--primary-shadow),
    0px 3px 0 var(--primary-shadow), 0px 4px 0 var(--primary-shadow),
    0px 5px 0 var(--primary-shadow), 0px 6px 0 var(--primary-shadow),
    0px 7px 0 var(--primary-shadow), 0px 8px 0 var(--primary-shadow),
    0px 9px 0 var(--primary-shadow), 0px 10px 0 var(--primary-shadow),
    0px 11px 0 var(--primary-shadow), 0px 12px 0 var(--primary-shadow),
    0px 13px 0 var(--primary-shadow), 0px 14px 0 var(--primary-shadow),
    0px 15px 14px var(--primary-shadow), 0px 15px 1px var(--primary-shadow),
    0px 0px 14px var(--primary-shadow);
}

.link,
.content__secondary-header {
  margin: 0;
}

.link {
  font-weight: 700;
  font-size: 10rem;
}

.text-visible .content__letter {
  opacity: 1 !important;
  background: none;
}

.content__letter {
  position: relative;
  color: var(--primary-text)
}

.text-visible .letter-0, .text-visible .letter-1, .text-visible .letter-2,.text-visible .letter-3 {
  display: none !important;
}

.letter-0 {
  transition: 500ms;
  position: absolute;
  top: -5px;
  left: 5px;
  width: 100%;
  -webkit-text-stroke: 0.1rem var(--primary-text-outline);
  text-stroke: 0.1rem var(--primary-text-outline);
  color: transparent;
}

.letter-1 {
  transition: 500ms;
  position: absolute;
  top: 10px;
  left: -10px;
  width: 100%;
  -webkit-text-stroke: 0.1rem var(--secondary-text-outline);
  text-stroke: 0.1rem var(--secondary-text-outline);
  color: transparent;
}

.letter-2 {
  transition: 500ms;
  position: absolute;
  top: 3px;
  left: -5px;
  width: 100%;
  -webkit-text-stroke: 0.1rem var(--tertiary-text-outline);
  text-stroke: 0.1rem var(--tertiary-text-outline);
  color: transparent;
}

.letter-3 {
  transition: 500ms;
  position: absolute;
  top: -5px;
  left: 5px;
  width: 100%;
  -webkit-text-stroke: 0.1rem var(--primary-text-outline);
  text-stroke: 0.1rem var(--primary-text-outline);
  color: transparent;
}

@keyframes blink {
  0% {
    color: var(--dark-background);
  }

  25% {
    color: var(--secondary-accent);
  }

  100% {
    color: var(--dark-background);
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0 1vw;
  }
  .content__primary-header {
    font-size: 6rem;
  }
  .content__overlap1 {
    top: -2px;
    left: 2px;
    width: 100%;
    -webkit-text-stroke: 0.05rem var(--primary-text-outline);
    text-stroke: 0.05rem var(--primary-text-outline);
  }
  .content__overlap2 {
    top: 4px;
    left: -4px;
    width: 100%;
    -webkit-text-stroke: 0.05rem var(--secondary-text-outline);
    text-stroke: 0.05rem var(--secondary-text-outline);
  }
  
  .content__overlap3 {
    top: 2px;
    left: -2px;
    width: 100%;
    -webkit-text-stroke: 0.05rem var(--tertiary-text-outline);
    text-stroke: 0.05rem var(--tertiary-text-outline);
  }

  .letter-0 {
    top: -2px;
    left: 2px;
    -webkit-text-stroke: 0.05rem var(--primary-text-outline);
    text-stroke: 0.05rem var(--primary-text-outline);
  }
  
  .letter-1 {
    top: 4px;
    left: -4px;
    -webkit-text-stroke: 0.05rem var(--secondary-text-outline);
    text-stroke: 0.05rem 4px var(--secondary-text-outline);
  }
  
  .letter-2 {
    top: 2px;
    left: -2px;
    -webkit-text-stroke: 0.05rem var(--tertiary-text-outline);
    text-stroke: 0.05rem var(--tertiary-text-outline);
  }
  
  .letter-3 {
    top: -2px;
    left: 2px;
    -webkit-text-stroke: 0.05rem var(--primary-text-outline);
    text-stroke: 0.05rem var(--primary-text-outline);
  }

  .link {
    font-size: 6rem;
  }
}

@media screen and (max-width: 576px) {
  .mouse-pointer {
    display: none;
  }

  .main {
    padding: 0 1vw;
  }

  .content__primary-header {
    font-size: 3rem;
  }

  .content__secondary-header {
    font-size: 1.5rem;
  }

  .content__paragraph {
    font-size: 1rem;
  }

  .text-container--right {
    margin-right: 0;
  }

  .link {
    font-size: 4rem;
  }

  .text-shadow {
    color: var(--primary-text);

    text-shadow: 0px 0px 0px var(--primary-shadow),
      0px 1px 0 var(--primary-shadow), 0px 1px 0 var(--primary-shadow),
      0px 2px 0 var(--primary-shadow), 0px 2px 0 var(--primary-shadow),
      0px 3px 0 var(--primary-shadow), 0px 3px 0 var(--primary-shadow),
      0px 4px 0 var(--primary-shadow), 0px 4px 0 var(--primary-shadow),
      0px 5px 0 var(--primary-shadow), 0px 5px 0 var(--primary-shadow),
      0px 6px 0 var(--primary-shadow), 0px 6px 0 var(--primary-shadow),
      0px 7px 0 var(--primary-shadow), 0px 7px 0 var(--primary-shadow),
      0px 8px 7px var(--primary-shadow), 0px 8px 1px var(--primary-shadow),
      0px 0px 7px var(--primary-shadow);
  }
}
